import request from '../utils/request'
import config from './config'

/**
 * 机构信息
 */

// 行政区划
export function administrativeDivision(params) {
	return request({
		url: `${config.CIVIL_HOST}/civilAffairsInstitutionalFiling/administrativeDivision`,
		method: 'get',
		params,
	})
}

// 数据字典
export function getDictList(params) {
	return request({
		url: `${config.CIVIL_HOST}/pensionDictInfo/getDictList`,
		method: 'get',
		params,
	})
}

// 下载机构备案模板
export function templateDownload(data) {
	return request({
		url: `${config.CIVIL_HOST}/civilAffairsInstitutionalFiling/templateDownload`,
		method: 'get',
		data,
		responseType: 'blob',
	})
}

// 机构备案分页查询
export function institutionalFilingPageList(data) {
	return request({
		url: `${config.CIVIL_HOST}/civilAffairsInstitutionalFiling/institutionalFilingPageList`,
		method: 'post',
		data,
	})
}

// 机构备案详情
export function institutionalFilingDetails(params) {
	return request({
		url: `${config.CIVIL_HOST}/civilAffairsInstitutionalFiling/institutionalFilingDetails`,
		method: 'get',
		params,
	})
}
